import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './Configuration.module.scss';

const Configuracion: React.FC = () => {
    const navigate = useNavigate();

    const [styleEnLinea, setStyleEnLinea] = useState({});
    const [styleEnLlamada, setStyleEnLlamada] = useState({});
    const [styleAlarma, setStyleAlarma] = useState({});
    const [styleAlarmaSonora, setStyleAlarmaSonora] = useState({});
    const [styleVerCamara, setStyleVerCamara] = useState({});

    const [styleEnLineaBoton, setStyleEnLineaBoton] = useState({});
    const [styleEnLlamadaBoton, setStyleEnLlamadaBoton] = useState({});
    const [styleAlarmaBoton, setStyleAlarmaBoton] = useState({});
    const [styleAlarmaSonoraBoton, setStyleAlarmaSonoraBoton] = useState({});
    const [styleVerCamaraBoton, setStyleVerCamaraBoton] = useState({});
    
    const btnEnLineaClick = async () => {
      try {
          const params = {
              toggle_Online: ''
          };
          const response = await axios.post('/api/totem_estados', params);
          if(response.data == 'Totem_Online')
          {
              setStyleEnLinea({
                  background: 'conic-gradient(var(--green), var(--green), var(--green), var(--green)) !important'
              });
              setStyleEnLineaBoton({
                filter: 'brightness(0) saturate(100%) invert(30%) sepia(54%) saturate(1099%) hue-rotate(326deg) brightness(97%) contrast(92%)'
              });
          }
          else
          {
              setStyleEnLinea({
                  background: 'conic-gradient(var(--red), var(--red), var(--red), var(--red)) !important'
              });
              setStyleEnLineaBoton({
                filter: 'filter: brightness(0) saturate(100%) invert(62%) sepia(98%) saturate(421%) hue-rotate(84deg) brightness(98%) contrast(84%)'
              });
          }
        } catch (err) {
          console.error(err);
      }
    };

    const btnEnllamadaClick = async () => {
        try {
            const params = {
                toggle_LED: ''
            };
            const response = await axios.post('/api/totem_estados', params);
            if(response.data == 'LED_is_on')
            {
                setStyleEnLlamada({
                    background: 'conic-gradient(var(--green), var(--green), var(--green), var(--green)) !important'
                });
            }
            else
            {
                setStyleEnLlamada({
                    background: 'conic-gradient(var(--red), var(--red), var(--red), var(--red)) !important'
                });
            }
          } catch (err) {
             console.error(err);
        }
    };

    const btnAlarmaClick = async () => {
        try {
            const params = {
              toggle_AV: ''
            };
            const response = await axios.post('/api/totem_estados', params);
            if(response.data == 'Alarma_visual_is_on')
            {
                setStyleAlarma({
                    background: 'conic-gradient(var(--green), var(--green), var(--green), var(--green)) !important'
                });
                setStyleEnLlamada({
                    background: 'conic-gradient(var(--green), var(--green), var(--green), var(--green)) !important'
                });
            }
            else
            {
                setStyleAlarma({
                    background: 'conic-gradient(var(--red), var(--red), var(--red), var(--red)) !important'
                });
                setStyleEnLlamada({
                    background: 'conic-gradient(var(--red), var(--red), var(--red), var(--red)) !important'
                });
            }
          } catch (err) {
            console.error(err);
        }
    };

    const btnAlarmaSonoraClick = async () => {
        try {
            const params = {
                toggle_AS: ''
            };
            const response = await axios.post('/api/totem_estados', params);
            if(response.data == 'Alarma_sonora_is_on')
            {
                setStyleAlarmaSonora({
                    background: 'conic-gradient(var(--green), var(--green), var(--green), var(--green)) !important'
                });
            }
            else
            {
                setStyleAlarmaSonora({
                    background: 'conic-gradient(var(--red), var(--red), var(--red), var(--red)) !important'
                });
            }
          } catch (err) {
            console.error(err);
        }
    };

    const btnVerCamaraClick = async () => {
        try {
            const params = {
                Ver_camara: ''
            };
            const response = await axios.post('/api/totem_estados', params);
            if(response.data == 'camara_is_on')
            {
                setStyleVerCamara({
                    background: 'conic-gradient(var(--green), var(--green), var(--green), var(--green)) !important'
                });

                try{
                    navigate('/VistaMonitoreo');
                  }
                  catch (err){
                    console.error(err);
                  }
            }
            else
            {
                setStyleVerCamara({
                    background: 'conic-gradient(var(--red), var(--red), var(--red), var(--red)) !important'
                });
            }
          } catch (err) {
             console.error(err);
        }
    };

    useEffect(() => {
        const interval = setInterval(async () => {
            try {
                const params = {
                  check_Totem_status: ''
                };
                const response = await axios.post('/api/totem_estados', params);
                if(response.data == 'Totem_Online')
                {
                    setStyleEnLinea({
                        background: 'conic-gradient(var(--green), var(--green), var(--green), var(--green)) !important'
                    });
                }
                else
                {
                    setStyleEnLinea({
                        background: 'conic-gradient(var(--red), var(--red), var(--red), var(--red)) !important'
                    });
                }
              } catch (err) {
                console.error(err);
            }
        }, 1000); // Consultar cada 5 segundos (ajustar según sea necesario)
    
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const interval = setInterval(async () => {
            try {
                const params = {
                    check_LED_status: ''
                };
                const response = await axios.post('/api/totem_estados', params);
                if(response.data == 'LED_is_on')
                {
                    setStyleEnLlamada({
                        background: 'conic-gradient(var(--green), var(--green), var(--green), var(--green)) !important'
                    });
                }
                else
                {
                    setStyleEnLlamada({
                        background: 'conic-gradient(var(--red), var(--red), var(--red), var(--red)) !important'
                    });
                }
              } catch (err) {
                console.error(err);
            }
        }, 1000); // Consultar cada 5 segundos (ajustar según sea necesario)
    
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const interval = setInterval(async () => {
            try {
                const params = {
                    check_AV_status: ''
                };
                const response = await axios.post('/api/totem_estados', params);
                if(response.data == 'Alarma_visual_is_on')
                {
                    setStyleAlarma({
                        background: 'conic-gradient(var(--green), var(--green), var(--green), var(--green)) !important'
                    });
                }
                else
                {
                    setStyleAlarma({
                        background: 'conic-gradient(var(--red), var(--red), var(--red), var(--red)) !important'
                    });
                }
              } catch (err) {
                console.error(err);
            }
        }, 1000); // Consultar cada 5 segundos (ajustar según sea necesario)
    
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const interval = setInterval(async () => {
            try {
                const params = {
                    check_AS_status: ''
                };
                const response = await axios.post('/api/totem_estados', params);
                if(response.data == 'Alarma_sonora_is_on')
                {
                    setStyleAlarmaSonora({
                        background: 'conic-gradient(var(--green), var(--green), var(--green), var(--green)) !important'
                    });
                }
                else
                {
                    setStyleAlarmaSonora({
                        background: 'conic-gradient(var(--red), var(--red), var(--red), var(--red)) !important'
                    });
                }
              } catch (err) {
                console.error(err);
            }
        }, 1000); // Consultar cada 5 segundos (ajustar según sea necesario)
    
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const interval = setInterval(async () => {
            try {
                const params = {
                    check_camara: ''
                };
                const response = await axios.post('/api/totem_estados', params);
                if(response.data == 'camara_Online')
                {
                    setStyleVerCamara({
                        background: 'conic-gradient(var(--green), var(--green), var(--green), var(--green)) !important'
                    });
                }
                else
                {
                    setStyleVerCamara({
                        background: 'conic-gradient(var(--red), var(--red), var(--red), var(--red)) !important'
                    });
                }
              } catch (err) {
                console.error(err);
            }
        }, 1000); // Consultar cada 5 segundos (ajustar según sea necesario)
    
        return () => clearInterval(interval);
    }, []);

    return (
      <div className={styles.body}>
        <div className={styles.fullContainer}>
            <div className={styles.feedback}>
                <div className={styles.feedbackCard}>
                    <div className={styles.feedbackHeader}><h2>Totem 1</h2></div>
                    <div className={styles.feedbackBody}>
                        <div className={styles.keyboard}>
                            <div className={styles.keyboadrInner}>
                                <div className={styles.key} style={styleEnLinea}>
                                    <div className={styles.keyContent} onClick={btnEnLineaClick}> <img style={styleEnLineaBoton} className={styles.icon} src="/totem-online.svg" alt="En Linea" /></div>
                                </div>
                                <div className={styles.key} style={styleEnLlamada}>
                                    <div className={styles.keyContent} onClick={btnEnllamadaClick}>En llamada</div>
                                </div>
                                <div className={styles.key} style={styleAlarma}>
                                    <div className={styles.keyContent} onClick={btnAlarmaClick}>Alarma</div>
                                </div>
                                <div className={styles.key} style={styleAlarmaSonora}>
                                    <div className={styles.keyContent} onClick={btnAlarmaSonoraClick}>Alarma Sonora</div>
                                </div>
                                <div className={styles.key} style={styleVerCamara}>
                                    <div className={styles.keyContent} onClick={btnVerCamaraClick}>Ver Camara</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.feedback}>
                <div className={styles.feedbackCard}>
                    <div className={styles.feedbackHeader}><h2>Totem 2</h2></div>
                    <div className={styles.feedbackBody}>
                        <div className={styles.keyboard}>
                            <div className={styles.keyboadrInner}>
                                <div className={styles.key} style={styleEnLinea}>
                                    <div className={styles.keyContent}>En Linea</div>
                                </div>
                                <div className={styles.key} style={styleEnLlamada}>
                                    <div className={styles.keyContent}>En llamada</div>
                                </div>
                                <div className={styles.key} style={styleAlarma}>
                                    <div className={styles.keyContent}>Alarma</div>
                                </div>
                                <div className={styles.key} style={styleAlarmaSonora}>
                                    <div className={styles.keyContent}>Alarma Sonora</div>
                                </div>
                                <div className={styles.key} style={styleVerCamara}>
                                    <div className={styles.keyContent}>Ver Camara</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    );
  };
  
  export default Configuracion;