import React, { useState, useEffect, useRef } from 'react';
import ProtectorPantalla from '../../ProtectorPantalla/ProtectorPantalla';
import useIdle from '../../ProtectorPantalla/useIdle';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './SalaEspera.scss'

const SalaEspera = () => {
    const navigate = useNavigate();
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formSubmittedCamara, setFormSubmittedCamara] = useState(false);
    const formRef = useRef<HTMLFormElement>(null); // Especificar el tipo de referencia como HTMLFormElement
    const formRefCamara = useRef<HTMLFormElement>(null); // Especificar el tipo de referencia como HTMLFormElement

    const isIdle = useIdle(10000); // 5 segundos de inactividad

    document.querySelector('#button')?.addEventListener('click', (e) =>
    {
      console.log(e);
    })

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      console.log('Se presiono boton');
      try{
        navigate('/videollamada',); // Redireccionar a la página "Videollamada"
      }
      catch (err){
        console.error(err);
      }
    };

    const handleSubmit_camara = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      console.log('Se presiono boton');
      try{
        navigate('/VistaTotem',);
      }
      catch (err){
        console.error(err);
      }
    };

    useEffect(() => {
      const interval = setInterval(async () => {
        // Lógica para verificar cambios en la base de datos
        const response = await axios.post('/api/InicioVideollamada', { id: 1 });
        console.log(response.data);
        console.log(response.data.data[0].status);
        //console.log(response.data.data[0].en_llamada);
        
        //const hasDatabaseChanged = Math.random() < 0.5; // Simulación de cambio en la base de datos
        //const hasDatabaseChanged = false; // Simulación de cambio en la base de datos
        const hasDatabaseChanged = response.data.data[0].status;
  
        if (hasDatabaseChanged) {
          setFormSubmitted(true); // Actualizar el estado del formulario
        }
      }, 5000); // Consultar cada 5 segundos (ajustar según sea necesario)
  
      return () => clearInterval(interval);
    }, []);

    useEffect(() => {
      const interval = setInterval(async () => {
        // Lógica para verificar cambios en la base de datos
        const response = await axios.post('/api/VerCamaraTotem', { id: 1 });
        console.log(response.data);
        console.log(response.data.data[0].status);
        //console.log(response.data.data[0].en_llamada);
        
        //const hasDatabaseChanged = Math.random() < 0.5; // Simulación de cambio en la base de datos
        //const hasDatabaseChanged = false; // Simulación de cambio en la base de datos
        const hasDatabaseChanged = response.data.data[0].status;
  
        if (hasDatabaseChanged) {
          setFormSubmittedCamara(true); // Actualizar el estado del formulario
        }
      }, 5000); // Consultar cada 5 segundos (ajustar según sea necesario)
  
      return () => clearInterval(interval);
    }, []);
  
    // Envía el formulario automáticamente si formSubmitted es true
    useEffect(() => {
      if (formSubmitted && formRef.current) {
        console.log("esta entrando");
        //formRef.current.submit(); // Acceder y enviar el formulario usando la referencia
        try{
          navigate('/videollamada'); // Redireccionar a la página "Videollamada"
        }
        catch (err){
          console.error(err);
        }
      }
    }, [formSubmitted]);

    useEffect(() => {
      if (formSubmittedCamara && formRefCamara.current) {
        console.log("esta entrando");
        try{
          navigate('/VistaTotem'); // Redireccionar a la página "Videollamada"
        }
        catch (err){
          console.error(err);
        }
      }
    }, [formSubmittedCamara]);

  
    return (
      <div>
        {isIdle && <ProtectorPantalla />}

        <form ref={formRef} className='login-form' onSubmit={handleSubmit}>
            <input type='submit' value='IR A LLAMADA'></input>
        </form>

        <form ref={formRefCamara} className='login-form' onSubmit={handleSubmit_camara}>
            <input type='submit' value='VER CAMARA'></input>
        </form>

        <div className="mod">
          <div className="cube">
            
            <div className="faces f1">
              
              <div className="dot p1"></div>
              <div className="dot p2"></div>
              <div className="dot p3"></div>
              
              <div className="dot p4"></div>
              <div className="dot p5"></div>
              <div className="dot p6"></div>
              
              <div className="dot p7"></div>
              <div className="dot p8"></div>
              <div className="dot p9"></div>
          
            </div>
            
            <div className="faces f2">
              
              <div className="dot p10"></div>
              <div className="dot p11"></div>
              <div className="dot p12"></div>
              <div className="dot p13"></div>
          
            </div>
            
            <div className="faces f3">
              
              <div className="dot p1"></div>
              <div className="dot p2"></div>
              <div className="dot p3"></div>
              
              <div className="dot p4"></div>
              <div className="dot p5"></div>
              <div className="dot p6"></div>
              
              <div className="dot p7"></div>
              <div className="dot p8"></div>
              <div className="dot p9"></div>
          
            </div>
          
            <div className="faces f4">
              
              <div className="dot p10"></div>
              <div className="dot p11"></div>
              <div className="dot p12"></div>
              <div className="dot p13"></div>
          
            </div>
          
            <div className="faces f5">
              
              <div className="dot p1"></div>
              <div className="dot p2"></div>
              <div className="dot p3"></div>
              
              <div className="dot p4"></div>
              <div className="dot p5"></div>
              <div className="dot p6"></div>
              
              <div className="dot p7"></div>
              <div className="dot p8"></div>
              <div className="dot p9"></div>
          
            </div>
          
            <div className="faces f6">
              
              <div className="dot p10"></div>
              <div className="dot p11"></div>
              <div className="dot p12"></div>
              <div className="dot p13"></div>
          
            </div>
          
            <div className="faces f7">
              
              <div className="dot p1"></div>
              <div className="dot p2"></div>
              <div className="dot p3"></div>
              
              <div className="dot p4"></div>
              <div className="dot p5"></div>
              <div className="dot p6"></div>
              
              <div className="dot p7"></div>
              <div className="dot p8"></div>
              <div className="dot p9"></div>
          
            </div>
          
            <div className="faces f8">
              
              <div className="dot p10"></div>
              <div className="dot p11"></div>
              <div className="dot p12"></div>
              <div className="dot p13"></div>
          
            </div>
          
            <div className="faces f9">
              
              <div className="dot p1"></div>
              <div className="dot p2"></div>
              <div className="dot p3"></div>
              
              <div className="dot p4"></div>
              <div className="dot p5"></div>
              <div className="dot p6"></div>
              
              <div className="dot p7"></div>
              <div className="dot p8"></div>
              <div className="dot p9"></div>
          
            </div>
          
            <div className="faces f10">
              
              <div className="dot p10"></div>
              <div className="dot p11"></div>
              <div className="dot p12"></div>
              <div className="dot p13"></div>
          
            </div>
          
            <div className="faces f11">
              
              <div className="dot p1"></div>
              <div className="dot p2"></div>
              <div className="dot p3"></div>
              
              <div className="dot p4"></div>
              <div className="dot p5"></div>
              <div className="dot p6"></div>
              
              <div className="dot p7"></div>
              <div className="dot p8"></div>
              <div className="dot p9"></div>
          
            </div>
            
          </div>
          
          <div className="project-name">
            <div className="l1">Okip</div>
            <div className="l2">Seguridad Privada</div>
          </div>
          
        </div>

      </div>
    );
  };
  
  export default SalaEspera;