import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './components/Login/Login';
import Videollamada from './components/Videollamada/Videollamada';
import VideollamadaMonitorista from './components/Videollamada/VideollamadaMonitorista';
import Configuracion from './components/Configuracion/Configuracion';
import SalaEspera from './components/Videollamada/SalaEspera/SalaEspera';
import VistaTotem from './components/Monitoreo/VistaTotem';
import VistaMonitoreo from './components/Monitoreo/VistaMonitoreo';


const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={< Login />} />
          {/* <Route path="/login" element={<Login />} /> */}
          <Route path="/videollamada" element={<Videollamada />} />
          <Route path="/videollamadaMonitorista" element={<VideollamadaMonitorista />} />
          <Route path="/Configuracion" element={<Configuracion />} />
          <Route path="/SalaEspera" element={<SalaEspera />} />
          <Route path='/VistaTotem' element={<VistaTotem />} />
          <Route path='/VistaMonitoreo' element={<VistaMonitoreo />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;