import { useState, useEffect } from 'react';

const useIdle = (timeout: number) => {
  const [isIdle, setIsIdle] = useState(false);
  let timer: NodeJS.Timeout;

  const resetTimer = () => {
    setIsIdle(false);
    clearTimeout(timer);
    timer = setTimeout(() => setIsIdle(true), timeout);
  };

  useEffect(() => {
    const events = ['mousemove', 'keydown', 'mousedown', 'touchstart'];

    const handleEvent = () => resetTimer();

    events.forEach(event => window.addEventListener(event, handleEvent));

    timer = setTimeout(() => setIsIdle(true), timeout);

    return () => {
      events.forEach(event => window.removeEventListener(event, handleEvent));
      clearTimeout(timer);
    };
  }, [timeout]);

  return isIdle;
};

export default useIdle;