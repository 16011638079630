import React from 'react';
import { transform } from 'typescript';

const ProtectorPantalla: React.FC = () => {
  return (
    <div style={styles.container}>
      <video style={styles.video} autoPlay loop muted>
        <source src="/video-totem-okip.mp4" type="video/mp4" />
        Tu navegador no soporta el video.
      </video>
    </div>
  );
};

const styles = {
    container: {
        position: 'fixed' as 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 1)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
        transform: 'rotate(270deg)',
    },
    video: {
        width: '80%',
        height: 'auto',
    },
};

export default ProtectorPantalla;