import React, { useState, useEffect, useRef } from 'react';
import { ZegoInvitationType, ZegoUIKitLanguage, ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import { useNavigate } from 'react-router-dom';
import ProtectorPantalla from '../ProtectorPantalla/ProtectorPantalla';
import useIdle from '../ProtectorPantalla/useIdle';
import axios from 'axios';

function randomID(len: number) {
    let result = '';
    if (result) return result;
    var chars = '12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP',
      maxPos = chars.length,
      i;
    len = len || 5;
    for (i = 0; i < len; i++) {
      result += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return result;
}

// get token
function generateToken(tokenServerUrl: string, userID: string) {
    // Obtain the token interface provided by the App Server
    return fetch(
      `${tokenServerUrl}/access_token?userID=${userID}&expired_ts=7200`,
      {
        method: 'GET',
      }
    ).then((res) => res.json());
}

export function getUrlParams(
    url: string = window.location.href
  ): URLSearchParams {
    let urlStr = url.split('?')[1];
    return new URLSearchParams(urlStr);
}

// async function getToken()
// {
//   try {
//     const response = await axios.post('/api/parametros', { });
//     return response.data.data[1].valor;
//   } catch (err) {
//     console.error(err);
//     return "";
//   }
// }

async function getUser()
{
  try {
    const response = await axios.post('/api/parametros', { });
    return response.data.data[2].valor;
  } catch (err) {
    console.error(err);
    return "";
  }
}

async function getSala()
{
  try {
    const response = await axios.post('/api/parametros', { });
    return response.data.data[3].valor;
  } catch (err) {
    console.error(err);
    return "";
  }
}

export default function VistaTotem() {
    const navigate = useNavigate();
    const containerRef = useRef<HTMLDivElement>(null);
  
    const roomID = getUrlParams().get('roomID') || randomID(5);
    const userID = '28580'; //randomID(5);
    const userName = "Invitado";

    const isIdle = useIdle(1000); // 5 segundos de inactividad

    useEffect(() => {
        const myMeeting = async () => {
          const { token } = await generateToken(
            'https://nextjs-token.vercel.app/api',
            userID
          );
          const kitToken = ZegoUIKitPrebuilt.generateKitTokenForProduction(
            1484647939,
            token,
            '3759',
            userID,
            userName
          );
    
          try {
            const zp = ZegoUIKitPrebuilt.create(kitToken);
            zp.joinRoom({
              container: containerRef.current!,
              sharedLinks: [
                {
                  name: 'Personal link',
                  url:
                    window.location.origin +
                    window.location.pathname +
                    '?roomID=' +
                    roomID,
                },
              ],
              scenario: {
                mode: ZegoUIKitPrebuilt.GroupCall,
              },
              onLeaveRoom: async () => {
                  console.log('Finalizo la llamada');
                  try {
                    zp.destroy();
                    const params = {
                      apagar_camara: ''
                    };
                    const response = await axios.post('/api/totem_estados', params);
                    navigate('/SalaEspera');
                  } catch (err) {
                    console.error(err);
                    alert('Error');
                  }
              },
              onUserLeave: async () => {
                  console.log('abandono la sala');
                  try {
                    zp.destroy();
                    const params = {
                      apagar_camara: ''
                    };
                    const response = await axios.post('/api/totem_estados', params);
                    console.log('abandono la salaaaaaa');
    
                    navigate('/SalaEspera');
                    //window.location.reload();
                  } catch (err) {
                    console.error(err);
                  }
              },
              showPreJoinView: false,
    
              showLayoutButton: false,
              //showNonVideoUser: false,
              showOnlyAudioUser: false,
              showScreenSharingButton: false,
              showPinButton: false,
              showMyCameraToggleButton: false,
              showMyMicrophoneToggleButton: false,
              showLeaveRoomConfirmDialog: false,  //confirmacion para salir
              showTextChat: false,
              showUserList: false,
              showRoomTimer: true,
              // preJoinViewConfig: {
              //   title: "Un monitorista lo atendera en un momento"
              // },
              showAudioVideoSettingsButton: false,
              showInviteToCohostButton: false,
              showLeavingView: false,
              showRoomDetailsButton: false
            });
          } catch (error) {
            console.log(error);
          }
        };
    
        if (containerRef.current) {
          myMeeting();
        }
      }, [userID, userName, roomID, navigate]);

      return (
        <div>
          {isIdle && <ProtectorPantalla />}

          <div
            className="myCallContainer"
            ref={containerRef}
            style={{ width: '100vw', height: '100vh' }}
          ></div>
        </div>
      );
}