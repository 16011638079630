import React, { useState, useEffect, useRef } from 'react';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function randomID(len: number) {
  let result = '';
  if (result) return result;
  var chars = '12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP',
    maxPos = chars.length,
    i;
  len = len || 5;
  for (i = 0; i < len; i++) {
    result += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return result;
}

// get token
function generateToken(tokenServerUrl: string, userID: string) {
  // Obtain the token interface provided by the App Server
  return fetch(
    `${tokenServerUrl}/access_token?userID=${userID}&expired_ts=7200`,
    {
      method: 'GET',
    }
  ).then((res) => res.json());
}

export function getUrlParams(
  url: string = window.location.href
): URLSearchParams {
  let urlStr = url.split('?')[1];
  return new URLSearchParams(urlStr);
}

export default function VistaMonitoreo() {
  const navigate = useNavigate();
  const roomID = getUrlParams().get('roomID') || randomID(5);
  const userID = "8851";
  const userName = "Monitorista"; 
  const containerRef = useRef<HTMLDivElement>(null);
  const [NombreUsuario, CambioNombreUsuario] = useState('');

  useEffect(() => {
    const myMeeting = async () => {
      console.log(NombreUsuario);
      const { token } = await generateToken(
        'https://nextjs-token.vercel.app/api',
        userID
      );
      const kitToken = ZegoUIKitPrebuilt.generateKitTokenForProduction(
        1484647939,
        token,
        '3759',
        userID,
        userName
      );

      console.log(kitToken);

      try {
        const zp = ZegoUIKitPrebuilt.create(kitToken);
        
        zp.joinRoom({
          container: containerRef.current!,
          sharedLinks: [
            {
              name: 'Personal link',
              url:
                window.location.origin +
                window.location.pathname +
                '?roomID=' +
                roomID,
            },
          ],
          scenario: {
            mode: ZegoUIKitPrebuilt.GroupCall,
          },
          onLeaveRoom: async () => {
            console.log('Finalizo la llamada');
            try {
              zp.destroy();
              const params = {
                apagar_camara: ''
              };
              const response = await axios.post('/api/totem_estados', params);
              navigate('/Configuracion');
            } catch (err) {
              console.error(err);
              alert('Error');
            }
          },
          onUserLeave: async () => {
            console.log('abandono la sala');
            try {
              zp.destroy();
              const params = {
                apagar_camara: ''
              };
              const response = await axios.post('/api/totem_estados', params);
              navigate('/Configuracion');
            } catch (err) {
              console.error(err);
            }
          },
          turnOnCameraWhenJoining: false,
          onUserAvatarSetter: (userList: any) => {
            console.log(userList);
            userList.forEach((user: { setUserAvatar: (arg0: string) => void; }) => {
              console.log(user);
              user.setUserAvatar("/avatar_hombre.jpg");
            });
          },
          showTurnOffRemoteCameraButton: true,
          showTurnOffRemoteMicrophoneButton: true,
          showLeaveRoomConfirmDialog: false,

          showPreJoinView: false,
        });
      } catch (error) {
        console.log(error);
      }
    };

    if (containerRef.current) {
      myMeeting();
    }
  }, [NombreUsuario, userID, userName, roomID]);

    return (
      <div>
        <div
          className="myCallContainer"
          ref={containerRef}
          style={{ width: '100vw', height: '100vh' }}>
        </div>
      </div>
    );
}